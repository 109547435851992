import RubrikService from './services/rubriks'
import VideoService from './services/videos'
import PageConfigService from './services/moduleConfig'
import SectionConfigService from './services/rubrikConfig'
import { Options } from '../../services.utils/request-types';
// eslint-disable-next-line no-unused-vars
import * as dataSchemas from './utils/DataSchemas'

import UserContentService from './services/userContent'
import { escapedString } from '../../services.utils/helpers'
import API from '../../services.utils/config'

const objectAssignDeep = require(`object-assign-deep`)

export class Client {
  options: Options
  private rubrikService!: RubrikService
  private videoService!: VideoService
  private pageConfigService!: PageConfigService
  private sectionConfigService!: SectionConfigService
  private userContentService!: UserContentService
  /**
   * Initiate client instance
   * @param options Optional. Set options for HTTP requests
   */
  constructor(options?: Options) {
    const defaultOptions = {
      headers: {},
      baseURL: API.baseUrl,
      version: API.version,
      timeout: API.timeout,
      responseType: 'json',
      signOut: options?.signOut
    }
    this.options = objectAssignDeep({}, defaultOptions, options)
  }

  /**
   * Configure client instance
   * @param options Optional. Set options for HTTP requests
   */
  configure = (options?: Options) => {
    this.options = objectAssignDeep(this.options, options)
    this.rubrikService = new RubrikService(this.options)
    this.videoService = new VideoService(this.options)
    this.pageConfigService = new PageConfigService(this.options)
    this.sectionConfigService = new SectionConfigService(this.options)
    this.userContentService = new UserContentService(this.options)
  }

  /**
   * Meditation rubrics
   */

  /**
   * Get all rubrics
   */
  getAllRubriks = (
    paginationOption: dataSchemas.PaginationOption = { offset: 0, limit: 100, published: true, sort_direction: -1 }
  ): Promise<dataSchemas.Pagination<dataSchemas.Rubrik>> =>
    this.rubrikService.getAll({
      ...paginationOption,
      search_text: escapedString(paginationOption?.search_text)
    })

  /**
   * Get rubrics by id
   * @param categoryId Category id
   * @param rubrikId Rubrik id
   */
  getRubrikById = (rubrikId: number): Promise<dataSchemas.Rubrik> =>
    this.rubrikService.getById(rubrikId)

  /**
   * Post rubrics
   * @param categoryId Category id
   * @param body Body of rubrics
   */
  createRubriks = (
    body: dataSchemas.RubrikRequest
  ): Promise<dataSchemas.Rubrik> => this.rubrikService.createRubrik(body)

  /**
   * Post rubrics
   * @param categoryId Category id
   * @param body Body of rubrics
   */
  createRubriksImage = (
    rubrikId: number,
    body: dataSchemas.ImageRequest
  ): Promise<dataSchemas.ImageInterface> => this.rubrikService.createRubrikImage(rubrikId, body)

  /**
   * Update rubric
   * @param categoryId Category id
   * @param rubrikId Rubrik id
   * @param body Body of rubric
   */
  updateRubrik = (
    rubrikId: number,
    body: dataSchemas.RubrikRequest
  ): Promise<dataSchemas.Rubrik> =>
    this.rubrikService.editRubrik(rubrikId, body)

  /**
   * Delete rubric
   * @param categoryId Category id
   * @param rubrikId Rubrik id
   */
  deleteRubriks = async (rubrikId: number) => {
    const rubric = await this.getRubrikById(rubrikId)
    return this.rubrikService
      .deleteRubrik(rubric.id)
      .then(async () => {
        return true
      })
      .catch((e) => {
        return Promise.reject({
          errors: 'Something went wrong when deleting a rubric.',
          error: e
        })
      })
  }

  /**
   * Delete rubric
   * @param categoryId Category id
   * @param rubrikId Rubrik id
   */
  deleteRubriksImage = async (rubrikId: number) => {
    const rubric = await this.getRubrikById(rubrikId)
    return this.rubrikService
      .deleteRubrikImage(rubric.id)
      .then(async () => {
        return true
      })
      .catch((e) => {
        return Promise.reject({
          errors: 'Something went wrong when deleting a rubric.',
          error: e
        })
      })
  }

  /**
   * Meditation Videos
   */

  /**
   * Get all videos
   */
  getAllVideos = (
    paginationOption: dataSchemas.VideoPaginationOption = { offset: 1, limit: 100, sort_direction: -1 }
  ): Promise<dataSchemas.VideoPagination<dataSchemas.Video>> =>
    this.videoService.getAll({
      ...paginationOption,
      search_text: escapedString(paginationOption?.search_text)
    })

  /**
   * Get all public videos
   */
  getAllPublicVideos = (
    paginationOption: dataSchemas.VideoPaginationOption = { offset: 1, limit: 100, sort_direction: -1 }
  ): Promise<dataSchemas.VideoPagination<dataSchemas.Video>> =>
    this.videoService.getAllPublic({
      ...paginationOption,
      search_text: escapedString(paginationOption?.search_text)
    })

  /**
   * Get video by id
   * @param videoId Video id
   */
  getVideoById = (data: dataSchemas.GetVideoById): Promise<dataSchemas.Video> =>
    this.videoService.getById(data)

  /**
   * Get public video by id
   * @param videoId Video id
   */
  getPublicVideoById = (data: dataSchemas.GetVideoById): Promise<dataSchemas.Video> =>
    this.videoService.getByIdPublic(data)

  /**
   * Post video
   * @param body Body of video
   */
  createVideo = (body: dataSchemas.VideoRequest): Promise<dataSchemas.Video> =>
    this.videoService.createVideo(body)

  /**
   * Post video image
   * @param body Body of video
   */
  createVideoImage = (
    videoId: number,
    body: dataSchemas.ImageRequest
  ): Promise<dataSchemas.Video> =>
    this.videoService.createVideoImage(videoId, body)

  /**
   * Post video image
   * @param body Body of video
   */
  deleteVideoImage = (videoId: number): Promise<dataSchemas.Video> =>
    this.videoService.deleteVideoImage(videoId)

  /**
   * Post video attachment
   * @param body Body of video
   */
  createVideoAttachment = (
    videoId: number,
    title: string,
    body: dataSchemas.AttachmentRequest,
  ): Promise<dataSchemas.Video> =>
    this.videoService.createVideoAttachment(videoId, title, body)

  /**
   * Post video attachment
   * @param body Body of video
   */
  deleteVideoAttachment = (
    videoId: number,
    attachmentId: number
  ): Promise<dataSchemas.Video> =>
    this.videoService.deleteVideoAttachment(videoId, attachmentId)

  /**
   * Post video reference
   * @param body Body of video
   */
  createVideoReference = (
    videoId: number,
    body: dataSchemas.VideoReferenceRequest
  ): Promise<dataSchemas.Reference> =>
    this.videoService.createVideoReference(videoId, body)

  /**
   * Update video
   * @param videoId Video id
   * @param body Body of video
   */
  updateVideo = (
    videoId: number,
    body: dataSchemas.VideoRequest
  ): Promise<dataSchemas.Video> => this.videoService.editVideo(videoId, body)

  /**
   * Delete video
   * @param videoId Video id
   */
  deleteVideo = async (videoId: number) => {
    const video = await this.getVideoById({ video_id: videoId })
    return this.videoService
      .deleteVideo(video.id)
      .then(async () => {
        return true
      })
      .catch((e) => {
        return Promise.reject({
          errors: 'Something went wrong when deleting a video.',
          error: e
        })
      })
  }

  /**
   * Delete video reference
   * @param videoId Video id
   * @param referenceId reference id
   */
  deleteVideoReference = async (videoId: number, referenceId: number) => {
    const video = await this.getVideoById({ video_id: videoId })
    return this.videoService
      .deleteVideoReference(video.id, referenceId)
      .then(async () => {
        return true
      })
      .catch((e) => {
        return Promise.reject({
          errors: 'Something went wrong when deleting a video.',
          error: e
        })
      })
  }

  /**
   * ModuleConfig
   */

  /**
   * Get the module configuration
   */

  getModuleConfig = (
  ): Promise<dataSchemas.ModuleConfig> =>
    this.pageConfigService.getModuleConfig()
  /**
   * Update the page configuration
   * @param body Body of page configuration
   */
  updateModuleConfig = (
    body: dataSchemas.ModuleConfigRequest
  ): Promise<dataSchemas.ModuleConfig> =>
    this.pageConfigService.updateModuleConfig(body)

  /**
   * RubrikConfig
   */

  /**
   * Get the module configuration
   */
  getRubrikConfig = (
  ): Promise<dataSchemas.RubrikConfig> =>
    this.sectionConfigService.getRubrikConfig()

  /**
   * Update the page configuration
   * @param body Body of page configuration
   */
  createRubrikSection = (
    body: dataSchemas.SectionRequest
  ): Promise<dataSchemas.Section> =>
    this.sectionConfigService.createSection(body)

  /**
   * Update the page configuration
   * @param body Body of page configuration
   */
  updateRubrikSection = (
    sectionId: number,
    body: dataSchemas.SectionRequest
  ): Promise<dataSchemas.Section> =>
    this.sectionConfigService.updateSection(sectionId, body)

  /**
   * Update the page configuration
   * @param body Body of page configuration
   */
  deleteRubrikSection = (sectionId: number): Promise<boolean> => {
    return this.sectionConfigService
      .deleteSection(sectionId)
      .then(async () => {
        return true
      })
      .catch((e) => {
        console.log(`Err : ${JSON.stringify(e, null, 2)}`)
        return Promise.reject({
          errors: 'Something went wrong when deleting a section.',
          error: e
        })
      })
  }

  /**
   * Get all user contents
   */
  getUserContent = (
    paginationOption: dataSchemas.VideoFavoritePaginationOption = {
      offset: 0,
      limit: 25
    }
  ): Promise<dataSchemas.VideoPagination<dataSchemas.Video>> =>
    this.userContentService.getUserContent({ ...paginationOption, search_text: escapedString(paginationOption?.search_text) })

  /**
   * Set that a video started
   */
  startViewingVideo = (body: dataSchemas.UserContentRequest): Promise<string> =>
    this.userContentService.startViewingVideo(body)

  /**
   * Set the video duration of the user
   */
  setVideoDurationFoUser = (
    data: dataSchemas.UserContentLogDurationRequest
  ): Promise<string> => this.userContentService.setVideoDurationFoUser(data)


  /**
   * Post a favorite video
   * @param video_id video id
   */
  createFavoriteVideo = (
    data: dataSchemas.UserContentRequest
  ): Promise<dataSchemas.UserContentRequest> =>
    this.userContentService.createFavoriteVideo(data)


  /**
   * un favorite a video
   * @param video_id video id
   */
  unFavoriteVideo = (
    data: dataSchemas.UserContentRequest
  ): Promise<dataSchemas.UserContentRequest> =>
    this.userContentService.unFavoriteVideo(data)

  /**
   * create a video view
   * @param data video id
   */
  createView = (
    data: dataSchemas.GetVideoById
  ): Promise<dataSchemas.GetVideoById> =>
    this.userContentService.createView(data)

  /**
   * Clone video
   * @param video_id Video id
   */
  cloneVideo = (
    video_id: number,
  ): Promise<dataSchemas.Video> => this.videoService.cloneVideo(video_id)

}

export * as types from './utils/DataSchemas'

import DailyMailMeService from './services/meService'
import DailyMailAdminService from './services/adminService'
import { Options } from '../../services.utils/request-types';
// eslint-disable-next-line no-unused-vars
import * as dataSchemas from './utils/DataSchemas'

import { escapedString } from '../../services.utils/helpers'
import API from '../../services.utils/config'

const objectAssignDeep = require(`object-assign-deep`)

export class Client {
  options: Options
  private dailyMailMeService!: DailyMailMeService
  private dailyMailAdminService!: DailyMailAdminService

  /**
   * Initiate client instance
   * @param options Optional. Set options for HTTP requests
   */
  constructor(options?: Options) {
    const defaultOptions = {
      headers: {},
      baseURL: API.baseUrl,
      version: API.version,
      timeout: API.timeout,
      responseType: 'json',
      signOut: options?.signOut
    }
    this.options = objectAssignDeep({}, defaultOptions, options)
  }

  /**
   * Configure client instance
   * @param options Optional. Set options for HTTP requests
   */
  configure = (options?: Options) => {
    this.options = objectAssignDeep(this.options, options)
    this.dailyMailMeService = new DailyMailMeService(this.options)
    this.dailyMailAdminService = new DailyMailAdminService(this.options)
  }

  /**
   * Get arcived daily mails
   */
  getAllArchivedMails = (
    paginationOption: dataSchemas.PaginationOption = { offset: 0, limit: 20 }
  ): Promise<dataSchemas.Pagination<dataSchemas.DailyMail>> =>
    this.dailyMailMeService.getAllArchivedMails({
      ...paginationOption,
      search_text: escapedString(paginationOption?.search_text)
    })


  /**
 * Get today mails
 */
  getTodayMails = (): Promise<dataSchemas.TodayDailyMail> =>
    this.dailyMailMeService.getTodayMails()

  /**
   * Get daily mail by day
   * @query
   */
  getDailyMailByDay = (daily_mail_date: string): Promise<dataSchemas.DailyMail> =>
    this.dailyMailMeService.getDailyMailByDay(daily_mail_date)

  /**
   * Get daily message by day
   * @query
   */
  getDailyMessageByDay = (daily_message_date: string): Promise<dataSchemas.DailyMessage> =>
    this.dailyMailMeService.getDailyMessageByDay(daily_message_date)

  /**
   * Get daily mails by id
   * @param dailyMailId
   */
  getById = (dailyMailId: number): Promise<dataSchemas.DailyMail> =>
    this.dailyMailAdminService.getById(dailyMailId)

  /**
   * Post daily mail
   * @param body Body of daily mail
   */
  createDailyMail = (
    body: dataSchemas.DailyMailRequest
  ): Promise<dataSchemas.DailyMail> => this.dailyMailAdminService.createDailyMail(body)

  /**
   * Update daily mail
   * @param dailyMailId
   * @param body Body of daily mail
   */
  editDailyMail = (
    dailyMailId: number,
    body: dataSchemas.DailyMailRequest
  ): Promise<dataSchemas.DailyMail> =>
    this.dailyMailAdminService.editDailyMail(dailyMailId, body)


  /**
 * Delete daily mail
 * @param dailyMailId
 */
  deleteDailyMail = async (dailyMailId: number) => {
    const dailyMail = await this.getById(dailyMailId)
    return this.dailyMailAdminService
      .deleteDailyMail(dailyMail.id)

  }

  /**
 * Post daily message
 * @param body Body of daily message
 */
  createDailyMessage = (
    body: dataSchemas.DailyMessageRequest
  ): Promise<dataSchemas.DailyMessage> => this.dailyMailAdminService.createDailyMessage(body)

  /**
   * Get daily message by id
   * @param dailyMailId
   */
  getDailyMessageById = (messageId: number): Promise<dataSchemas.DailyMessage> =>
    this.dailyMailAdminService.getDailyMessageById(messageId)


  /**
   * Update daily message
   * @param messageId
   * @param body Body of daily message
   */
  editDailyMessage = (
    messageId: number,
    body: dataSchemas.DailyMessageRequest
  ): Promise<dataSchemas.DailyMessage> =>
    this.dailyMailAdminService.editDailyMessage(messageId, body)


  /**
 * Delete daily message
 * @param messageId
 */
  deleteDailyMessage = async (messageId: number) => {
    const dailyMessage = await this.getDailyMessageById(messageId)
    return this.dailyMailAdminService
      .deleteDailyMessage(dailyMessage.id)

  }

  /**
   * Post daily mail image
   * @param dailyMailId
   * @param body Body of daily mail image
   */
  createDailyMailImage = (
    dailyMailId: number,
    body: dataSchemas.DailyMailImageRequest
  ): Promise<dataSchemas.ImageInterface> => this.dailyMailAdminService.createDailyMailImage(dailyMailId, body)


  /**
   * Delete daily mail image
   * @param dailyMailId
   */
  deleteDailyMailImage = async (dailyMailId: number): Promise<string> => {
    return this.dailyMailAdminService
      .deleteDailyMailImage(dailyMailId)
  }

  /**
   * Get all categories
   */
  getAllCategories = (): Promise<Array<dataSchemas.Categories>> =>
    this.dailyMailAdminService.getAllCategories()

  /**
   * Get all messages
   */
  getAllMessages = (pagination: dataSchemas.PaginationOption = { offset: 0, limit: 20, sort_by: 'created_at', sort_direction : -1 }): Promise<dataSchemas.Pagination<dataSchemas.DailyMessage>> =>
    this.dailyMailAdminService.getAllMessages({
      ...pagination,
      search_text: escapedString(pagination?.search_text)
    })

  /**
   * Get all daily mails
   */
  getAllDailyMails = (pagination: dataSchemas.PaginationOption = { offset: 0, limit: 20, sort_by: 'day' }): Promise<dataSchemas.Pagination<dataSchemas.DailyMail>> =>
    this.dailyMailAdminService.getAllDailyMails({
      ...pagination,
      search_text: escapedString(pagination?.search_text)
    })

  /**
   * Get all me daily news
   */
  getDailyNews = (pagination: dataSchemas.PaginationOption = { offset: 0, limit: 20, sort_direction: -1 }): Promise<dataSchemas.Pagination<dataSchemas.DailyNew>> =>
    this.dailyMailMeService.getDailyNews({
      ...pagination,
      search_text: escapedString(pagination?.search_text)
    })

  /**
   * Get all admin daily news
   */
  getAllDailyNews = (pagination: dataSchemas.PaginationDailyNewsOption = { offset: 0, limit: 20, sort_direction: -1 }): Promise<dataSchemas.Pagination<dataSchemas.DailyNew>> =>
    this.dailyMailAdminService.getAllDailyNews({
      ...pagination,
      search_text: escapedString(pagination?.search_text)
    })


  /**
 * Get daily new by id
 * @param dailyNewId
 */
  getDailyNewById = (dailyNewId: number): Promise<dataSchemas.DailyNew> =>
    this.dailyMailAdminService.getDailyNewById(dailyNewId)

  /**
   * Post daily new
   * @param body Body of daily new
   */
  createDailyNew = (
    body: dataSchemas.DailyNewsRequest
  ): Promise<dataSchemas.DailyNew> => this.dailyMailAdminService.createDailyNew(body)

  /**
   * Update daily new
   * @param dailyNewId
   * @param body Body of daily new
   */
  editDailyNew = (
    dailyNewId: number,
    body: dataSchemas.DailyNewsRequest
  ): Promise<dataSchemas.DailyNew> =>
    this.dailyMailAdminService.editDailyNew(dailyNewId, body)


  /**
 * Delete daily new
 * @param dailyNewId
 */
  deleteDailyNew = async (dailyNewId: number) => {
    return this.dailyMailAdminService.deleteDailyNew(dailyNewId)
  }

  /**
 * Create daily new image
 * @param dailyNewId daily new id
 * @param body Body of daily new image
 */
  createDailyNewImage = (
    dailyNewId: number,
    body: dataSchemas.DailyMailImageRequest
  ): Promise<any> => this.dailyMailAdminService.createDailyNewImage(dailyNewId, body)


  /**
   * Delete daily new image
   * @param dailyNewId daily new id
   */
  deleteDailyNewImage = async (dailyNewId: number) => {
    return this.dailyMailAdminService.deleteDailyNewImage(dailyNewId)
  }


  /**
   * Post daily message image
   * @param dailyMessageId
   * @param body Body of daily mail image
   */
  createDailyMessageImage = (
    dailyMessageId: number,
    body: dataSchemas.DailyMailImageRequest
  ): Promise<dataSchemas.ImageInterface> => this.dailyMailAdminService.createDailyMessageImage(dailyMessageId, body)


  /**
   * Delete daily message image
   * @param dailyMessageId
   */
  deleteDailyMessageImage = async (dailyMessageId: number): Promise<string> => {
    return this.dailyMailAdminService
      .deleteDailyMessageImage(dailyMessageId)
  }
}

export * as types from './utils/DataSchemas'

import {
  VideoRequest,
  VideoPaginationOption,
  ImageRequest,
  AttachmentRequest,
  VideoReferenceRequest,
  GetVideoById
} from '../utils/DataSchemas'
import request from '../../../services.utils/request'
import {Options} from '../../../services.utils/request-types'

function getPaginationQuery(paginationOption: VideoPaginationOption) {
  let query = `?offset=${paginationOption.offset}&limit=${paginationOption.limit}`

  if (typeof paginationOption.published !== "undefined") {
    query += `&published=${paginationOption.published}`
  }

  if (typeof paginationOption.tags !== "undefined") {
    const tagsFormat: string = paginationOption.tags
      .map((tag) => `&tags=${tag}`)
      .join('')
    query += tagsFormat
  }

  if (typeof paginationOption.rubric_ids !== "undefined") {
    const rubrikIdsFormat: string = paginationOption.rubric_ids
      .map((id) => `&rubric_ids=${id}`)
      .join('')
    query += rubrikIdsFormat
  }

  if (typeof paginationOption.search_text !== "undefined") {
    query += `&search_text=${paginationOption.search_text}`
  }

  if (typeof paginationOption.sort_by !== "undefined") {
    query += `&sort_by=${paginationOption.sort_by}`
  }

  if (typeof paginationOption.video_duration !== "undefined") {
    query += `&video_duration=${paginationOption.video_duration}`
  }

  if (typeof paginationOption.sort_direction !== 'undefined') {
    query += `&sort_direction=${paginationOption.sort_direction}`
  }

  if (typeof paginationOption.expert_ids !== "undefined") {
    const expertsIds: string = paginationOption.expert_ids
      .map((p: any, index: number) =>
        index === 0 && !query ? `?expert_ids=${p}` : `&expert_ids=${p}`
      )
      .join('')
    query += expertsIds
  }

  return query
}
export default class VideoService {
  options: Options

  constructor(options: Options) {
    this.options = options
  }

  async getAll(paginationOption: VideoPaginationOption) {
    const query = getPaginationQuery(paginationOption)
    return request('GET', `/videos/${query}`, this.options)
  }
  async getAllPublic(paginationOption: VideoPaginationOption) {
    const query = getPaginationQuery(paginationOption)
    return request('GET', `/public/videos/${query}`, this.options)
  }

  async getById({ video_id }: GetVideoById) {
    let query = ''

    return request('GET', `/videos/${video_id}${query}`, this.options)
  }

  async getByIdPublic({ video_id }: GetVideoById) {
    let query = ''

    return request('GET', `/public/videos/${video_id}${query}`, this.options)
  }

  async createVideo(data: VideoRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('POST', `/videos/`, requestOptions)
  }

  async createVideoImage(videoId: number, data: ImageRequest) {
    const formData = new FormData()
    formData.append('image', data.image)

    const requestOptions: Options = {
      ...this.options,
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.options.headers
      },
      data: formData
    }

    return request('POST', `/videos/${videoId}/images`, requestOptions)
  }

  async createVideoAttachment(
    videoId: number,
    title: string,
    data: AttachmentRequest,
  ) {
    const formData = new FormData()
    formData.append('attachment', data.attachment)

    const requestOptions: Options = {
      ...this.options,
      headers: {
        'Content-Type': 'multipart/form-data',
        ...this.options.headers
      },
      data: formData
    }

    return request(
      'POST',
      `/videos/${videoId}/attachments?title=${title}`,
      requestOptions
    )
  }

  async createVideoReference(videoId: number, data: VideoReferenceRequest) {
    const query = `?title=${data.title}&external_url=${data.external_url}`

    return request(
      'POST',
      `/videos/${videoId}/references${query}`,
      this.options
    )
  }

  async editVideo(videoId: number, data: VideoRequest) {
    const requestOptions: Options = {
      ...this.options,
      data: data
    }
    return request('PUT', `/videos/${videoId}`, requestOptions)
  }

  async deleteVideo(videoId: number) {
    return request('DELETE', `/videos/${videoId}`, this.options)
  }

  async deleteVideoImage(videoId: number) {
    return request('DELETE', `/videos/${videoId}/images`, this.options)
  }

  async deleteVideoAttachment(videoId: number, attachmentId: number) {
    return request(
      'DELETE',
      `/videos/${videoId}/attachments/${attachmentId}`,
      this.options
    )
  }

  async deleteVideoReference(videoId: number, referenceId: number) {
    return request(
      'DELETE',
      `/videos/${videoId}/references/${referenceId}`,
      this.options
    )
  }

  async cloneVideo(video_id: number) {
    return request('PUT', `/admin/videos/${video_id}/clone/`, this.options)
  }
}
